import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

interface IDownloadResumeButtonData {
  placeholderFile: {
    publicURL: string;
  };
}

interface IDownloadResumeButtonProps {
  className?: string;
}

const DownloadResumeButton = ({ className }: IDownloadResumeButtonProps) => {
  const data: IDownloadResumeButtonData = useStaticQuery(graphql`
    query {
      placeholderFile: file(relativePath: { eq: "resume.pdf" }) {
        publicURL
      }
    }
  `)

  const onButtonClick = () => {
    const a = document.createElement('a');
    const { placeholderFile: { publicURL } } = data;
    a.href = publicURL;
    a.download = 'Cristian Dumitru Resume.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <button
      className={classNames('btn btn-primary', className)}
      onClick={onButtonClick}
    >
      Download Resume
    </button>
  );
}

export default DownloadResumeButton;
