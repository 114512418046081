import * as React from 'react';
import { graphql } from 'gatsby'
import { FixedObject } from 'gatsby-image';

import Layout from '../components/Layout/Layout';
import { Home } from '../components/Home/Home';
import { About } from '../components/About/About';
import { Experience } from '../components/Experience/Experience';
import { INavbarItem } from '../components/Sidebar/Sidebar';
import { IGraphQlResponse, IProfile, ISkillsCategory, IWork } from '../models';
import { processGraphQlResponse } from '../utils';
import { Skills } from '../components/Skills/Skills';

export const query = graphql`
  query {
    dataJson(basics: {}) {
      basics {
        name,
        label,
        email,
        summary,
        currentTechnologies,
        website,
        profiles {
          network,
          url
        },
        picture {
          childImageSharp {
            fixed(width: 350, height: 350, quality: 85) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      },
      work {
        company,
        position,
        website,
        startDate(formatString: "MMMM YYYY"),
        endDate(formatString: "MMMM YYYY"),
        highlights,
        technologies
      },
      skills {
        name,
        keywords
      }
    }  
  }
`;

interface IIndexPageProps {
  basics: {
    name: string;
    label: string;
    email: string;
    summary: string;
    currentTechnologies: string[];
    profiles: IProfile[];
    website: string;
    picture: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
  };
  work: IWork[];
  skills: ISkillsCategory[];
}

const IndexPage = (props: IGraphQlResponse<IIndexPageProps>) => {
  const navbarItems: INavbarItem[] = [
    {
      id: 'home',
      label: 'Home'
    },
    {
      id: 'about',
      label: 'About'
    },
    {
      id: 'skills',
      label: 'Skills'
    },
    {
      id: 'experience',
      label: 'Experience'
    }
  ];
  const {
    basics: {
      name,
      label,
      summary,
      currentTechnologies,
      email,
      profiles,
      website,
      picture
    },
    work,
    skills
  } = processGraphQlResponse(props);

  return (
    <Layout
      sidebarProps={{
        navbarItems,
        name,
        email,
        profiles
      }}
      footerProps={{
        name,
        email,
        profiles
      }}
      seoProps={{
        label,
        title: name,
        description: label,
        url: website,
        image: picture.childImageSharp.fixed.src
      }}
    >
      <section id="home">
        <Home
          name={name}
          label={label}
        />
      </section>
      <section id="about">
        <About
          summary={summary}
          currentTechnologies={currentTechnologies}
        />
      </section>
      <section id="skills">
        <Skills skills={skills} />
      </section>
      <section id="experience">
        <Experience
          work={work}
        />
      </section>
    </Layout>
  );
};

export default IndexPage;
