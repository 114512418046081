import * as React from 'react';
import { FaCheck } from 'react-icons/fa';
import classNames from 'classnames';

import { ISkillsCategory, SkillsCategoryNameEnum } from '../../models';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import FrontendIcon from '../../svg/frontend.svg';
import BackendIcon from '../../svg/backend.svg';
import OtherIcon from '../../svg/others.svg';
import BlockchainIcon from '../../svg/blockchain.svg';

import * as styles from './Skills.module.scss';

interface ISkillsProps {
  skills: ISkillsCategory[];
}

const SkillsCategoryIconMapper = {
  [SkillsCategoryNameEnum.Frontend]: <FrontendIcon />,
  [SkillsCategoryNameEnum.Backend]: <BackendIcon />,
  [SkillsCategoryNameEnum.Blockchain]: <BlockchainIcon />,
  [SkillsCategoryNameEnum.Others]: <OtherIcon />
}

export class Skills extends React.Component<ISkillsProps> {
  renderSkillKeyword = (keyword: string, index: number) => {
    return (
      <li key={keyword+index} className={styles.skillKeyword}>
        <span className="text-primary mr-1"><FaCheck /></span> {keyword}
      </li>
    );
  }

  renderSkillsCategory = (skillCategory: ISkillsCategory) => {
    const { name, keywords } = skillCategory;

    return (
      <div className="col-12 col-md-3 px-2 mt-5 mb-5 mb-md-0" key={name}>
        <div className="card shadow py-4 px-4 h-100">
          <div className={classNames('position-absolute d-inline-block rounded-circle text-center bg-primary', styles.iconContainer)}>
            {SkillsCategoryIconMapper[name]}
          </div>
          <p className="text-center font-weight-bold mt-8">{name}</p>
          <ul className="list-unstyled align-self-center">
            {keywords.map(this.renderSkillKeyword)}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="mt-8">
        <SectionTitle text="Skills" className="mb-6 text-center" />
        <div className="row pt-6">
          {this.props.skills.map(this.renderSkillsCategory)}
        </div>
      </div>
    );
  }
}
