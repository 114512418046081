export type SkillsCategoryNameType = 'Frontend'| 'Backend' | 'Others';

export enum SkillsCategoryNameEnum {
  Frontend = 'Frontend',
  Backend = 'Backend',
  Blockchain = 'Blockchain',
  Others = 'Others',
}

export interface ISkillsCategory {
  name: SkillsCategoryNameType;
  keywords: string[];
}
