import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import classNames from 'classnames';

import * as styles from './PortretImage.module.scss';

interface IPortretImageData {
  smallSize: {
    basics: {
      picture: {
        childImageSharp: {
          fixed: FixedObject;
        };
      };
    };
  };
  largeSize: {
    basics: {
      picture: {
        childImageSharp: {
          fixed: FixedObject;
        };
      };
    };
  };
}

const PortretImage = () => {
  const data: IPortretImageData = useStaticQuery(graphql`
    query {
      smallSize: dataJson {
        basics {
          picture {
            childImageSharp {
              fixed(width: 250, height: 250, quality: 85) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      },
      largeSize: dataJson {
        basics {
          picture {
            childImageSharp {
              fixed(width: 350, height: 350, quality: 85) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }  
    }
  `);

  return (
    <>
      <div className={classNames(styles.container, styles.smallContainer)}>
        <Img
          fixed={data.smallSize.basics.picture.childImageSharp.fixed}
          className={styles.portret}
          critical={true}
          loading="eager"
        />
      </div>
      <div className={classNames(styles.container, styles.largeContainer)}>
        <Img
          fixed={data.largeSize.basics.picture.childImageSharp.fixed}
          className={styles.portret}
          critical={true}
          loading="eager"
        />
      </div>
    </>
  );
};

export default PortretImage;
