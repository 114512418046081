export type ProfileUrlType = 'LinkedId' | 'Github';

export enum ProfileUrlEnum {
  LinkedId = 'LinkedId',
  GitHub = 'Github'
}

export interface IProfile {
  network: string;
  url: ProfileUrlType;
}
