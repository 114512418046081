import * as React from 'react';
import classNames from 'classnames';

import * as styles from './SectionTitle.module.scss';

interface ISectionTitleProps {
  text: string;
  className?: string;
}

export class SectionTitle extends React.Component<ISectionTitleProps> {
  render() {
    const { text, className } = this.props;

    return (
      <div className={classNames(styles.title, className)}>
        <h2>{text}</h2>
      </div>
    );
  }
}
