import * as React from 'react';

import { IProfile } from '../../models';
import SocialIconsList from '../SocialIconsList/SocialIconsList';

import * as styles from './Footer.module.scss';

export interface IFooterProps {
  name: string;
  email: string;
  profiles: IProfile[];
}

class Footer extends React.Component<IFooterProps> {
  renderSocialIconsList = () => {
    const { email, profiles } = this.props;

    return (
      <SocialIconsList
        email={email}
        profiles={profiles}
        iconClassName={styles.iconClassName}
      />
    );
  }

  renderCopyright = () => {
    return (
      <div className="mt-4">
        © {this.props.name} {new Date().getFullYear()}, Built with{' '}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </div>
    );
  }

  render () {
    return (
      <footer className="mt-6 p-6 text-center">
        {this.renderSocialIconsList()}
        {this.renderCopyright()}
      </footer>
    );
  }
}

export default Footer;
