import * as React from 'react';
import { FaEnvelope, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import classNames from 'classnames';

import { IProfile } from '../../models';

import * as styles from './SocialIconsList.module.scss';

interface ISocialIconsListProps {
  email: string;
  profiles: IProfile[];
  iconClassName?: string;
}

class SocialIconsList extends React.Component<ISocialIconsListProps> {
  renderSocialIcon = (icon: React.ReactNode, url: string, name: string) => {
    return (
      <li className={classNames('nav-item rounded-circle', styles.socialIcon, this.props.iconClassName)}>
        <a
          className="nav-link p-0 active"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={name}
        >
          {icon}
        </a>
      </li>
    );
  };

  getProfileUrl = (network: string) => {
    return this.props.profiles.find(profile => profile.network === network)?.url || '#';
  }

  render () {
    const { email } = this.props;

    return (
      <ul className="nav justify-content-center">
        {this.renderSocialIcon(<FaEnvelope />, `mailTo: ${email}`, 'email')}
        {this.renderSocialIcon(<FaLinkedinIn />, this.getProfileUrl('LinkedIn'),'linkenin')}
        {this.renderSocialIcon(<FaGithub />, this.getProfileUrl('GitHub'), 'github')}
      </ul>
    );
  }
}

export default SocialIconsList;
