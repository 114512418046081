import * as React from 'react';
import classNames from 'classnames';

interface ITechnologiesListProps {
  technologies: string[];
  className?: string;
}

class TechnologiesList extends React.Component<ITechnologiesListProps>{
  renderTechnology = (technology: string, index: number) => {
    return (
      <span
        key={technology+index}
        className="badge badge-pill badge-primary mr-3 mt-2"
      >
        {technology}
      </span>
    );
  }

  render () {
    const { technologies, className} = this.props;

    return (
      <div className={classNames('d-flex flex-wrap', className)}>
        {technologies.map(this.renderTechnology)}
      </div>
    );
  }
}

export default TechnologiesList;
