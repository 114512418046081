export interface IWork {
  company: string;
  position: string;
  website: string;
  startDate: string;
  endDate?: string;
  highlights: string[];
  technologies: string[];
}

export const CURRENT_JOB_END_DATE = 'Present';
