import * as React from 'react';
import classNames from 'classnames';

import PortretImage from '../PortretImage/PortretImage';
import DownloadResumeButton from '../DownloadResumeButton/DownloadResumeButton';

import * as styles from './Home.module.scss';

interface IHomeProps {
  name: string;
  label: string;
}

export class Home extends React.Component<IHomeProps> {
  renderText = () => {
    const helloText = 'I\'m ';
    const { name, label } = this.props;

    return (
      <div
        className={classNames(
          'd-flex flex-column mr-sm-3 mr-md-6 mr-lg-8 order-sm-1 mt-6',
          styles.textContainer
        )}
      >
        <span className="mb-4">Hi,</span>
        <h1 className="font-weight-normal mb-0">
          {helloText}
          <span className="font-weight-bold">{name}</span>
        </h1>
        <span className="font-weight-normal mb-6">
          {label}
        </span>
        <DownloadResumeButton />
      </div>
    );
  };

  renderImage = () => {
    return (
      <div className="order-sm-2">
        <PortretImage />
      </div>
    );
  };

  render() {
    return (
      <div
        className={classNames(
          'd-flex flex-column flex-sm-row flex-wrap flex-sm-nowrap justify-content-center align-items-center',
          styles.container
        )}
      >
        {this.renderImage()}
        {this.renderText()}
      </div>
    );
  }
}
