import * as React from 'react';
import classNames from 'classnames';

import AboutImage from '../../svg/about.svg';
import { SectionTitle } from '../SectionTitle/SectionTitle';
import TechnologiesList from '../TechnologiesList/TechnologiesList';

import * as styles from './About.module.scss';

interface IAboutProps {
  summary: string;
  currentTechnologies: string[];
}

export class About extends React.Component<IAboutProps> {
  renderText = () => {
    const { summary, currentTechnologies } = this.props;

    return (
      <div className={classNames('ml-md-8 mt-6 mt-md-0', styles.textContainer)}>
        <SectionTitle text="About Me" className="text-center text-md-left" />
        <div className="mt-4">
          {summary}
        </div>
        <div className="mt-5">
          <span>Here are a few technologies I&apos;ve been working with recently:</span>
          <TechnologiesList technologies={currentTechnologies}/>
        </div>
      </div>
    );
  };

  renderImage = () => {
    return <AboutImage className={styles.image} />;
  };

  render() {
    return (
      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center mt-8">
        {this.renderImage()}
        {this.renderText()}
      </div>
    );
  }
}
