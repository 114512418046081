import * as React from 'react';
import Helmet from 'react-helmet';

export interface ISEOProps {
  title: string;
  label: string;
  description: string;
  url: string;
  image: string;
  lang?: string;
  meta?: JSX.IntrinsicElements['meta'][];
}

const SEO = ({ title, label, description, lang = 'en', url, image, meta = [] }: ISEOProps) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${label}`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: `${title} | ${label}`,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'author',
          content: title,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:site_name',
          content: title,
        },
        {
          property: 'og:image',
          content: `${url}${image}`,
        },
        ...meta
      ]}
    />
  )
}

export default SEO;
