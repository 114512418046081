import * as React from 'react';

import Sidebar, { ISidebarProps } from '../Sidebar/Sidebar';
import Footer, { IFooterProps } from '../Footer/Footer';
import SEO, { ISEOProps } from '../SEO/SEO';

interface ILayoutProps {
  sidebarProps: ISidebarProps;
  footerProps: IFooterProps;
  seoProps: ISEOProps;
  children: React.ReactNode;
}

const Layout = ({ sidebarProps, footerProps, seoProps, children }: ILayoutProps) => {
  return (
    <Sidebar {...sidebarProps}>
      <SEO {...seoProps} />
      <div className="container-fluid pl-4 pr-4 pl-md-8 pr-md-8">
        {children}
        <Footer {...footerProps} />
      </div>
    </Sidebar>
  );
};

export default Layout;
