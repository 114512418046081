import * as React from 'react';
import classNames from 'classnames';

import { SectionTitle } from '../SectionTitle/SectionTitle';
import { CURRENT_JOB_END_DATE, IWork } from '../../models';
import TechnologiesList from '../TechnologiesList/TechnologiesList';

import * as styles from './Experience.module.scss';

interface IExperienceProps {
  work: IWork[];
}

export class Experience extends React.Component<IExperienceProps> {
  renderJobHighlight = (highlight: string, index: number) => {
    return (
      <li key={index}>{highlight}</li>
    );
  }

  renderJobPeriod = (startDate: string, endDate: string) => {
    return `${startDate} - ${endDate}`
  }

  renderJob = (job: IWork, index: number) => {
    const {
      company,
      startDate,
      endDate,
      position,
      highlights,
      technologies
    } = job;

    return (
      <li
        key={index}
        className={classNames(
          'bg-white rounded ml-3 p-4 shadow',
          styles.timelineItem
        )}
      >
        <div className={styles.timelineArrow} />
        <div className="d-flex align-items-baseline">
          <h3 className="h5 mb-0">
            {position}
          </h3>
          <span className="ml-2 ml-auto">
            {company}
          </span>
        </div>
        <span className={classNames('small', styles.jobPeriod)}>{this.renderJobPeriod(startDate, endDate || CURRENT_JOB_END_DATE)}</span>
        <ul className={classNames('mt-3', styles.highlightsList)}>
          {highlights.reverse().map(this.renderJobHighlight)}
        </ul>
        <TechnologiesList technologies={technologies} className="mt-2" />
      </li>
    );
  };

  render() {
    return (
      <div className="mt-8">
        <SectionTitle text="Experience" className="mb-6 text-center" />
        <div className="col-md-11 px-0 mx-auto">
          <ul className={styles.timeline}>
            {this.props.work.reverse().map(this.renderJob)}
          </ul>
        </div>
      </div>
    );
  }
}
