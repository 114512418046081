import * as React from 'react';
import classNames from 'classnames';
import { FaBars, FaTimes } from 'react-icons/fa';
import Scrollspy from 'react-scrollspy';
import { Link } from 'gatsby';

import { IProfile } from '../../models';
import SocialIconsList from '../SocialIconsList/SocialIconsList';

import * as styles from './Sidebar.module.scss';

export interface INavbarItem {
  id: string;
  label: string;
}

export interface ISidebarProps {
  navbarItems: INavbarItem[];
  name: string;
  email: string;
  profiles: IProfile[];
}

interface ISidebarState {
  navbarOpen: boolean;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  state = {
    navbarOpen: false
  };

  onClickNavbarToggle = () => {
    this.setState({
      navbarOpen: !this.state.navbarOpen
    });
  };

  renderNavbarToggle = () => {
    const { navbarOpen } = this.state;

    return (
      <button
        className={classNames('navbar-toggler', styles.toggleButton)}
        type="button"
        onClick={this.onClickNavbarToggle}
        aria-label="Toggle navigation"
      >
        {navbarOpen ? <FaTimes /> : <FaBars />}
      </button>
    );
  };

  renderName = () => {
    return (
      <Link to="/" className="text-center text-white text-decoration-none">
        <h1 className={styles.name}>
          {this.props.name}
        </h1>
      </Link>
    );
  };

  renderNavbarItem = ({ id, label }: INavbarItem) => {
    return (
      <li key={id} className={classNames('nav-item font-weight-bold', styles.navbarItem)}>
        <a className="nav-link" href={`#${id}`}>
          {label}
        </a>
      </li>
    );
  };

  onClickNavbarItem = () => {
    this.setState({
      navbarOpen: false
    });
  }

  renderNavbarItemList = () => {
    const { navbarItems } = this.props;
    const navbarItemsIds = navbarItems.map(item => item.id);

    return (
      <div className="mt-auto mb-auto">
        <Scrollspy
          items={navbarItemsIds}
          currentClassName={styles.current}
          className="navbar-nav flex-column align-items-center ml-0"
          onUpdate={this.onClickNavbarItem}
        >
          {this.props.navbarItems.map(item => this.renderNavbarItem(item))}
        </Scrollspy>
      </div>
    );
  };

  renderSocialIconList = () => {
    const { email, profiles } = this.props;

    return (
      <div className="mt-4 text-center">
        <SocialIconsList
          email={email}
          profiles={profiles}
          iconClassName={styles.iconClassName}
        />
      </div>
    );
  };

  renderChildren = () => {
    return <div className="w-auto">{this.props.children}</div>;
  };

  renderNavbar = () => {
    return (
      <div
        className={classNames('navbar-collapse collapse pt-4 p-lg-0', {
          'show': this.state.navbarOpen
        })}
      >
        <div className="d-flex flex-column align-items-center h-100">
          {this.renderNavbarItemList()}
          {this.renderSocialIconList()}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={classNames('sticky-top bg-primary text-white', styles.navbarContainer)}>
          <nav className={classNames('navbar navbar-expand-lg py-4 px-sm-4 px-sm-8 px-md-8 p-lg-6', styles.navbar)}>
            {this.renderName()}
            {this.renderNavbarToggle()}
            {this.renderNavbar()}
          </nav>
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}

export default Sidebar;
